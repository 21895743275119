<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large container">
      <div class="intro-header">
        <div class="sessons-header">
          <div class="form">
            <div class="header-buttons"><h2>Settings</h2></div>
          </div>
        </div>
      </div>
      <div class="side-nav-content-wrapper">
        <ul class="side-nav">
          <li
            v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            "
            @click="changeSubNav('teams')"
          >
            Teams
          </li>
          <li
            v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            "
            @click="changeSubNav('players')"
          >
            Players
          </li>
          <li v-else @click="changeSubNav('players')">Profile</li>
          <li
            v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            "
            @click="changeSubNav('oppositionTeams')"
          >
            Opposition teams
          </li>
          <li
            v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            "
            @click="changeSubNav('staff')"
          >
            Staff
          </li>
          <li
            v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            "
            @click="changeSubNav('archivedPlayers')"
          >
            Archived Players
          </li>
          <li @click="changeSubNav('userSettings')">Change password</li>
        </ul>
        <div class="content-with-side-nav">
          <template v-if="selectedNav === 'teams'">
            <div class="settings-wrapper">
              <h2>Teams</h2>
              <button
                v-if="userData.userType === 'Admin'"
                @click="openAddNewTeamModal()"
                class="green-button"
              >
                Add new team
              </button>

              <p>Total number of teams: {{ teams.length }}</p>
              <div v-if="teams.length > 0" class="">
                <vue-good-table
                  style-class="vgt-table bordered"
                  :columns="columnsTeams"
                  :rows="teams"
                  :search-options="{
                    enabled: true,
                    placeholder: 'Search teams',
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="
                        props.column.field == 'season_start_date' ||
                        props.column.field == 'season_end_date'
                      "
                    >
                      <span>{{
                        formatDates(props.formattedRow[props.column.field])
                      }}</span>
                    </span>
                    <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                      <b-button
                      @click="openEditTeamModal(props.row)"
                        variant="outline-warning"
                        v-b-tooltip.hover
                        title="Edit"
                        ><b-icon icon="pencil" aria-hidden="true"></b-icon
                      ></b-button>
                      <b-button
                      @click="veiwTeam(props.row)"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="View team"
                        ><b-icon icon="eye" aria-hidden="true"></b-icon
                      ></b-button>
                    </span>
                    <span v-else-if="props.column.field == 'teamIcon'">
                      <img
                        class="user-image-small"
                        :src="getImgUrl('default-image.jpg')"
                    /></span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </template>


          <template v-if="selectedNav === 'archivedPlayers'">
            <div class="settings-wrapper">
              <h2>Archived Players</h2>
            </div>

            <p>Total number of players: {{ allArchivedPlayersData.length }}</p>

            <div v-if="allArchivedPlayersData.length > 0" class="">
              <vue-good-table
                style-class="vgt-table bordered"
                :columns="columns"
                :rows="allArchivedPlayersData"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search archived users',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'dob'">
                    <span>{{
                      formatDates(props.formattedRow[props.column.field])
                    }}</span>
                  </span>
                  <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                  </span>
                  <span v-else-if="props.column.field == 'userImage'">
                    <span
                      v-if="
                        props.row.userImage === null ||
                        props.row.userImage === ''
                      "
                    >
                      <img
                        class="user-image-small"
                        :src="getImgUrl('default-image.jpg')"
                      />
                    </span>
                    <span v-else>
                      <img
                        class="user-image-small"
                        :src="getImgUrl(props.row.userImage)"
                      />
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div v-else>
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Users.</p>
              </div>
            </div>
          </template>


          <template v-if="selectedNav === 'players'">
            <div class="settings-wrapper">
              <h2>Players</h2>
            </div>

            <p>Total number of players: {{ allPlayersData.length }}</p>

            <div v-if="allPlayersData.length > 0" class="">
              <vue-good-table
                style-class="vgt-table bordered"
                :columns="columns"
                :rows="allPlayersData"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search users',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'dob'">
                    <span>{{
                      formatDates(props.formattedRow[props.column.field])
                    }}</span>
                  </span>
                  <span v-else-if="props.column.field == 'trialistId'">

                    <p v-if="props.row.trialistId === null">Signed player</p>
                    <p v-else>Trialist</p>
                  
                  </span>
    
                  <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                    <b-button
                    @click="openEditUserModal(props.row)"
                        variant="outline-warning"
                        v-b-tooltip.hover
                        title="Edit"
                        ><b-icon icon="pencil" aria-hidden="true"></b-icon
                      ></b-button>

                    <b-button
                      v-if="userData.userType === 'Admin'"
                      @click="goToPlayer(props.row)"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="view player profile"
                      ><b-icon icon="person" aria-hidden="true"></b-icon
                    ></b-button>

                    <b-button
                      v-if="userData.userType === 'Admin'"
                      @click="releasePlayerModal(props.row)"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Release Player"
                      ><b-icon icon="person" aria-hidden="true"></b-icon
                    ></b-button>
                  </span>
                  <span v-else-if="props.column.field == 'userImage'">
                    <span
                      v-if="
                        props.row.userImage === null ||
                        props.row.userImage === ''
                      "
                    >
                      <img
                        class="user-image-small"
                        :src="getImgUrl('default-image.jpg')"
                      />
                    </span>
                    <span v-else>
                      <img
                        class="user-image-small"
                        :src="getImgUrl(props.row.userImage)"
                      />
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div v-else>
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Users.</p>
              </div>
            </div>
          </template>

          <template v-if="selectedNav === 'staff'">
            <div class="settings-wrapper">
              <h2>Staff</h2>
            </div>

            <p>Total number of staff: {{ allStaffData.length }}</p>

            <div v-if="allStaffData.length > 0" class="">
              <vue-good-table
                style-class="vgt-table bordered"
                :columns="columnsStaff"
                :rows="allStaffData"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search users',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'dob'">
                    <span>{{
                      formatDates(props.formattedRow[props.column.field])
                    }}</span>
                  </span>
                  <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                    <b-button
                    @click="openEditUserModal(props.row)"
                        variant="outline-warning"
                        v-b-tooltip.hover
                        title="Edit"
                        ><b-icon icon="pencil" aria-hidden="true"></b-icon
                      ></b-button>
                      <b-button
                      v-if="userData.userType === 'Admin'"
                      @click="archiveUser(props.row)"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Archive staff member"
                      ><b-icon icon="trash" aria-hidden="true"></b-icon
                    ></b-button>
                  </span>
                  <span v-else-if="props.column.field == 'userImage'">
                    <span
                      v-if="
                        props.row.userImage === null ||
                        props.row.userImage === ''
                      "
                    >
                      <img
                        class="user-image-small"
                        :src="getImgUrl('default-image.jpg')"
                      />
                    </span>
                    <span v-else>
                      <img
                        class="user-image-small"
                        :src="getImgUrl(props.row.userImage)"
                      />
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div v-else>
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Users.</p>
              </div>
            </div>
          </template>
          <template v-if="selectedNav === 'oppositionTeams'">
            <div class="settings-wrapper">
              <h2>Opposition teams</h2>
              <button @click="openAddOppositionTeamModal()" class="green-button">
                Add Opposition Team
                </button>

                <div v-if="leagueTeams.length > 0" class="">
              <vue-good-table
                style-class="vgt-table bordered"
                :columns="columnsOppositionTeams"
                :rows="leagueTeams"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search teams',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'dob'">
                    <span>{{
                      formatDates(props.formattedRow[props.column.field])
                    }}</span>
                  </span>
                  <span class="action-buttons" v-else-if="props.column.field == 'actions'">

                    <b-button
                    @click="openAddOppositionTeamModal(props.row)"
                        variant="outline-warning"
                        v-b-tooltip.hover
                        title="Edit"
                        ><b-icon icon="pencil" aria-hidden="true"></b-icon
                      ></b-button>
                      <b-button
                      @click="deleteOppositionTeam(props.row)"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      ><b-icon icon="trash" aria-hidden="true"></b-icon
                    ></b-button>
                    
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div v-else>
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Teams.</p>
              </div>
            </div>
            </div>
          </template>
          <template v-if="selectedNav === 'userSettings'">
            <div class="settings-wrapper">
              <div class="password-wrapper form">
                <h2>Change password</h2>
                <div class="form__item">
                  <label class="form__label" for="password"
                    >Current Password</label
                  >

                  <input
                    class="form__input"
                    type="password"
                    v-model="oldPassword"
                    id="password"
                  />
                  <!--<p class="invalid-feedback" v-if="validationValues.password.state === true">{{ validationValues.password.text }}</p>-->
                </div>
                <div class="form__item">
                  <label class="form__label" for="password">New Password</label>
                  <input
                    class="form__input"
                    type="password"
                    v-model="newPassword"
                    id="password"
                  />
                  <!--<p class="invalid-feedback" v-if="validationValues.password.state === true">{{ validationValues.password.text }}</p>-->
                </div>

                <button @click="changePassword()" class="green-button">
                  Change Password
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <b-modal
      id="editTeam"
      size="lg"
      title="Edit Team"
      :hide-footer="Boolean(true)"
    >
      <div class="form">
        <div class="form__item">
          <label class="form__label" for="teamName">Team name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTeam.teamName"
            id="teamName"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="leagueName">League name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTeam.leagueName"
            id="leagueName"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="league_link">League URL</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTeam.league_link"
            id="league_link"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="age_group">Age group</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTeam.age_group"
            id="age_group"
            maxlength="4"
            pattern="\d{4}"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="season_start_date"
            >Season start date</label
          >
          <datepicker
            v-model="selectedTeam.season_start_date"
            name="season_start_date"
            id="season_start_date"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="season_end_date"
            >Season end date</label
          >
          <datepicker
            v-model="selectedTeam.season_end_date"
            name="season_end_date"
            id="season_end_date"
          />
        </div>

        <div class="form__item">
          <label
            v-b-tooltip.hover
            title="Each team must have a coach assigned to them"
            class="form__label"
            for="name"
            >Add coach
            <b-icon
              icon="exclamation-circle"
              style="width: 24px; height: 24px"
            ></b-icon
          ></label>
          <div v-if="changeHeadCoach === false">
            <span class="current-coach-change-link"
              >Head coach: {{ selectedTeam.name }}</span
            >
            <a @click="changeHeadCoach = true" href="javascript:;">Change head coach</a>
          </div>
            <div v-else class="wrap">
              <input
                class="form__input"
                type="text"
                v-model="selectedCoach.name"
                id="trainingPlanId"
              />
              <button @click="findCoachByName">Find coach by name</button>
          </div>
          <div></div>
          <div v-if="changeHeadCoach === true" class="training-plan-display">
            Head Coach: {{ selectedTeam.name }}
            <b-form-group
              v-if="selectedCoachesData.length > 0"
              class="find-coach-radio-wrapper"
            >
              <b-form-radio
                v-for="coach in selectedCoachesData"
                :key="coach.id"
                name="coach-radios"
                :value="coach.id"
                @change="
                  updateCurrentSelectedTeamCoachName(
                    coach.name,
                    coach.id,
                    selectedTeam.coach_id,
                    'Head'
                  )
                "
                >{{ coach.name }}</b-form-radio
              >
            </b-form-group>
            <p v-else>No coaches found</p>
          </div>
          <div v-else></div>
          <div></div>
          <p class="coach-header">Teams additional coaches list</p>
          
          <div></div>
          <div class="access-pool">
              <div v-for="coachs in selectedCoachesForSelectedTeam" :key="coachs.id">
              <span class="has-access" v-if="coachs.id !== selectedTeam.coach_id">Coach: {{ coachs.name }} <a @click="removeCoachFromTeam(coachs.id)">x</a></span>
            </div>

            </div>

            
            <div></div>
            <div>
              <a @click="addAnotherCoach = true" href="javascript:;">Add another coach</a>

              <div v-if="addAnotherCoach === true" class="add-anotherCoach">
              <input
              class="form__input"
              type="text"
              v-model="selectedCoach.name"
              id="trainingPlanId"
              />
              <button @click="findCoachByName">Find coach by name</button>

              <div class="training-plan-display">
              <b-form-group
                v-if="selectedCoachesData.length > 0"
                class="find-coach-radio-wrapper"
              >
                <b-form-radio
                  v-for="coach in selectedCoachesData"
                  :key="coach.id"
                  name="coach-radios"
                  :value="coach.id"
                  @change="
                    addAnotherCoachCall(
                      coach.id,
                      selectedTeam.id,
                      'Coach'
                    )
                  "
                  >{{ coach.name }}</b-form-radio
                >
              </b-form-group>
              <p v-else>No coaches found</p>
              </div>
            </div>

            </div>
        </div>

        <p class="small-text">
          Please note that the start and end dates of the season will define
          when the app begins recording all data. If there are any activities
          occurring outside the designated season that you wish to view within
          the app, we recommend commencing your season ahead of time.
        </p>

        <button @click="upDateTeam()" class="green-button">Edit Team</button>
      </div>
    </b-modal>

    <b-modal
      id="addNewTeam"
      size="lg"
      title="Add a new team"
      :hide-footer="Boolean(true)"
    >
      <div class="form">
        <div class="form__item">
          <label class="form__label" for="teamName">Team name</label>
          <input
            class="form__input"
            type="text"
            v-model="newTeamData.teamName"
            id="teamName"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="age_group">Age group</label>
          <input
            class="form__input"
            type="text"
            v-model="newTeamData.age_group"
            id="age_group"
            maxlength="4"
            pattern="\d{4}"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="leagueName">league name</label>
          <input
            class="form__input"
            type="text"
            v-model="newTeamData.leagueName"
            id="leagueName"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="league_link"
            >Link to the league table</label
          >
          <input
            class="form__input"
            type="text"
            v-model="newTeamData.league_link"
            id="league_link"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="season_start_date"
            >Season start date</label
          >
          <datepicker
            v-model="newTeamData.season_start_date"
            name="season_start_date"
            id="season_start_date"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="season_end_date"
            >Season end date</label
          >
          <datepicker
            v-model="newTeamData.season_end_date"
            name="season_end_date"
            id="season_end_date"
          />
        </div>

        <div class="form__item">
          <label
            v-b-tooltip.hover
            title="Each team must have a coach assigned to them"
            class="form__label"
            for="name"
            >Add coach
            <b-icon
              icon="exclamation-circle"
              style="width: 24px; height: 24px"
            ></b-icon
          ></label>
          <div class="wrap">
            <input
              class="form__input"
              type="text"
              v-model="selectedCoach.name"
              id="trainingPlanId"
            />
            <button @click="findCoachByName">Find coach by name</button>
          </div>
          <div></div>
          <div class="training-plan-display">
            <b-form-group
              v-if="selectedCoachesData.length > 0"
              class="find-coach-radio-wrapper"
            >
              <b-form-radio
                v-for="coach in selectedCoachesData"
                :key="coach.id"
                v-model="selectedCoach.id"
                name="coach-radios"
                :value="coach.id"
                @change="
                  updateCurrentSelectedTeamCoachName(
                    coach.name,
                    coach.id,
                    selectedTeam.coach_id,
                    'Head'
                  )"
                >{{ coach.name }}</b-form-radio
              >
            </b-form-group>
            <p v-else>No coaches found</p>
          </div>
          <div></div>
          <p v-if="missingCoachId === true" class="red-warning"><b-icon icon="exclamation-circle" aria-hidden="true"></b-icon> You need to add a coach.</p>
        </div>

        <p class="small-text">
          Please note that the start and end dates of the season will define
          when the app begins recording all data. If there are any activities
          occurring outside the designated season that you wish to view within
          the app, we recommend commencing your season ahead of time.
        </p>

        <button @click="addNewTeam()" class="green-button">Add Team</button>
      </div>
    </b-modal>
    <b-modal
      id="addOppositionTeam"
      size="lg"
      :title="editMode === false ? 'Add Opposition Team' : 'Edit Opposition Team'"
      :hide-footer="Boolean(true)"
    >

    <div class="form">
        <div class="form__item">
          <label class="form__label" for="teamName">Team name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedOppositionTeam.teamName"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="teamName">Pitch name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedOppositionTeam.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="address">Pitch address</label>

          <b-form-textarea
          v-model="selectedOppositionTeam.address"
          id="address"
          placeholder="Add address"
          rows="8"
        ></b-form-textarea>
        </div>

        <div class="form__item">
            <label class="form__label" for="postCode">PostCode</label>
            <input
              id="postCode"
              type="text"
              v-model="selectedOppositionTeam.postCode"
              name="postCode"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="pitchType">Pitch size</label>


            <b-form-select v-model="selectedOppositionTeam.pitch_size">
              <option disabled value="">Please select a pitch size</option>
            <option value="11-a-side">11-a-side</option>
            <option value="9-a-side">9-a-side</option>
            <option value="7-a-side">7-a-side</option>
            <option value="5-a-side">5-a-side</option>
            <option value="Other">Other</option>
            </b-form-select>

          </div>

          <div class="form__item">
            <label class="form__label" for="pitchType">Pitch type</label>


            <b-form-select v-model="selectedOppositionTeam.pitchType">
              <option disabled value="">Please select a pitch type</option>
            <option value="4G">4G</option>
            <option value="3G">3G</option>
            <option value="Grass">Grass</option>
            <option value="Other">Other</option>
            </b-form-select>

          </div>

    </div>


      <button 
      v-if="editMode === false"
      class="green-button" 
      @click="addOppositionTeam()"
        > Add team</button>
      
      <button 
      v-else
      class="green-button" 
      @click="editOppositionTeam()"
        > Update team</button>

  </b-modal>

    <b-modal
      id="areYouSureModal"
      size="lg"
      title="Edit Player"
      :hide-footer="Boolean(true)"
      @hidden="editUserModalClose()"
    >

      <h3>Are you sure?</h3>
      <p>
        If you proceed, this player will be archived. This action will remove
        them from all team activities and app functions, and they will lose
        access to the app entirely..
      </p>

      <b-button
        @click="archiveUser(playerToBeReleased)"
        variant="outline-danger"
        ><b-icon icon="person" aria-hidden="true"></b-icon
      > Release Player</b-button>

  </b-modal>

    <b-modal
      id="editUser"
      size="lg"
      title="Edit Player"
      :hide-footer="Boolean(true)"
      @hidden="editUserModalClose()"
    >
      <div class="form">
        <div class="form__item">
          <label class="form__label" for="name">Name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedPlayer.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="email">Email</label>
          <input
            :class="
              validationValues.email.state === true
                ? 'danger-outline form__input'
                : 'form__input'
            "
            type="email"
            @change="checkChangeVlaidation(selectedPlayer.email, 'email')"
            v-model="selectedPlayer.email"
            id="email"
          />
          <p
            class="invalid-feedback"
            v-if="validationValues.email.state === true"
          >
            {{ validationValues.email.text }}
          </p>
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="dob">DOB</label>
          <datepicker
            :class="
              selectedPlayer.dob === null ||
              selectedPlayer.dob === 'Invalid date'
                ? 'danger-outline form__input'
                : 'form__input'
            "
            v-model="selectedPlayer.dob"
            name="dob"
            id="dob"
          />
          <p
            class="invalid-feedback"
            v-if="
              selectedPlayer.dob === null ||
              selectedPlayer.dob === 'Invalid date'
            "
          >
            {{ validationValues.dob.text }}
          </p>
        </div>

        <ImageUploader
          @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
          @populateImageFileDataFromUpload="
            imageUploadObjectDataChanged($event)
          "
          @clearImageDataFromUpload="clearImageDataFromUpload()"
          :imageURL="selectedPlayer.userImage"
        />

        <div class="form__item">
          <label class="form__label" for="position">Position</label>

          <select id="position" v-model="selectedPlayer.position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="I played more than one position">
              I played more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="phone">Phone</label>
          <input
            class="form__input"
            type="number"
            v-model="selectedPlayer.phone"
            id="phone"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="parent_name"
            >Parent name</label
          >
          <input
            class="form__input"
            type="text"
            v-model="selectedPlayer.parent_name"
            id="parent_name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="emergency_contact_one"
            >Emergency Contact one</label
          >
          <input
            class="form__input"
            type="text"
            v-model="selectedPlayer.emergency_contact_one"
            id="emergency_contact_one"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="emergency_contact_two"
            >Emergency Contact two</label
          >
          <input
            class="form__input"
            type="text"
            v-model="selectedPlayer.emergency_contact_two"
            id="emergency_contact_two"
          />
        </div>

        <div class="form__item uk-form joined_date">
          <label class="form__label" for="joined_date">Joined date</label>
          <datepicker
            :class="
              selectedPlayer.dob === null ||
              selectedPlayer.joined_date === 'Invalid date'
                ? 'danger-outline form__input'
                : 'form__input'
            "
            v-model="selectedPlayer.joined_date"
            name="joined_date"
            id="joined_date"
          />
          <p
            class="invalid-feedback"
            v-if="
              selectedPlayer.joined_date === null ||
              selectedPlayer.joined_date === 'Invalid date'
            "
          >
            {{ validationValues.joinedDate.text }}
          </p>
        </div>

        <div class="form__item">
          <label class="form__label" for="emergency_contact_one">Address</label>

          <b-form-textarea
            v-model="selectedPlayer.address"
            rows="8"
            id="emergency_contact_one"
          ></b-form-textarea>
        </div>

        <div class="form__item uk-form post_code">
            <label class="form__label" for="post_code">Post code</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedPlayer.post_code"
              id="post_code"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="place_of_birth">Place of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedPlayer.place_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="country_of_birth">Country of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedPlayer.country_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form nationality">
            <label class="form__label" for="nationality">Nationality</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedPlayer.nationality"
              id="nationality"
            />
          </div>

          <div class="form__item uk-form previous_club">
            <label class="form__label" for="nationality">Previous club</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedPlayer.previous_club"
              id="previous_club"
            />
          </div>

        <div class="form__item">
          <label class="form__label" for="match_day_kit_number"
            >kit number</label
          >
          <input
            class="form__input"
            type="number"
            v-model="selectedPlayer.match_day_kit_number"
            id="match_day_kit_number"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="medical">Medical inforamtion</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedPlayer.medical"
            id="medical"
          />
        </div>

        <div
          v-if="
            selectedPlayer.userType !== 'Admin' &&
            selectedPlayer.userType !== 'Coach'
          "
          class="form__item"
        >
          <label class="form__label" for="emergency_contact_one"
            >Primary team</label
          >
          <div v-if="getTeamNameFromId(selectedPlayer.teamId) !== ''">
            <p>
              {{ getTeamNameFromId(selectedPlayer.teamId) }}
              <a @click="showTeamsSelection = true" href="javascript:;"
                >- Change primary team</a
              >
            </p>

            <div class="form__item" v-if="showTeamsSelection === true">
              <label class="form__label" for="coachSelect">Select team</label>
              <select
                v-model="selectedPlayerTeamValue"
                id="coachSelect"
                @change="changePlayersTeam($event)"
              >
                <option disabled value="">Please select a team</option>
                <option v-for="team in teams" :value="team.id" :key="team.id">
                  {{ team.teamName }}
                </option>
              </select>
            </div>

            <div class="more-team-information">
              <h3>
                {{ selectedPlayer.name }} is a memebr of
                {{ playersOtherTeamsData.length }} other team<span
                  v-if="playersOtherTeamsData.length > 1"
                  >'s</span
                >
              </h3>

              <div class="extra-teams-list">
                <p v-for="team in playersOtherTeamsData" :key="team.id">
                  {{ team.teamName }} -
                  <a
                    class="red-warning"
                    @click="removePlayerFromTeam(selectedPlayer.id, team.id)"
                    href="javascript:;"
                    >remove</a
                  >
                </p>
              </div>

              <a
                class="add-other-team-button"
                v-if="selectedPlayer.teamId !== 0"
                @click="showTeamsSelectionAddTeam = true"
                href="javascript:;"
                >Add another team</a
              >

              <div class="form__item" v-if="showTeamsSelectionAddTeam === true">
                <label class="form__label" for="coachSelect">Select team</label>
                <select
                  v-model="selectedPlayerTeamValue"
                  id="coachSelect"
                  @change="addPlayerToNewTeam($event)"
                >
                  <option disabled value="">Please select a team</option>
                  <option v-for="team in teams" :value="team.id" :key="team.id">
                    {{ team.teamName }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <p v-else>
            No current team
            <a @click="showTeamsSelection = true" href="javascript:;"
              >- Connect this player to a team</a
            >
          </p>
        </div>

        <button v-if="loadingStuff === false" @click="upDatePlayerProfile()" class="green-button">
          Edit profile
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
  POPULATE_SELECTED_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import { Games } from "@/services/games-service.js";
import AppTemplate from "../layouts/App";
import moment from "moment";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import ImageUploader from "../../components/ImageUploader";
import VueGoodTable from "vue-good-table";
import VTooltip from "v-tooltip";
import "vue-good-table/dist/vue-good-table.css";
import Datepicker from "vuejs-datepicker";
import goToPlayerMixin from "@/mixin/goToPlayer.js";

Vue.use(VueGoodTable);
Vue.use(VTooltip);

export default {
  name: "Settings",
  mixins: [goToPlayerMixin],
  components: { AppTemplate, SpinnerLoader, Datepicker, ImageUploader },
  data() {
    return {
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        dob: {
          state: false,
          text: "Please add DOB please!",
        },
        joinedDate: {
          state: false,
          text: "Please add joined date please!",
        },
      },
      missingCoachId: false,
      addAnotherCoach: false,
      changeHeadCoach: false,
      changeCoach: false,
      selectedCoachesData: [],
      selectedCoach: {},
      selectedPlayerTeamValue: null,
      oldPassword: null,
      newPassword: null,
      loadingStuff: false,
      allPlayersData: [],
      allStaffData: [],
      allArchivedPlayersData: [],
      selectedNav: "teams",
      showTeamsSelection: false,
      showTeamsSelectionAddTeam: false,
      teams: [],
      newTeamData: {
        teamName: "",
        leagueName: "",
        clubId: "",
        league_link: "",
        season_start_date: null,
        season_end_date: null,
        age_group: "0000",
      },
      userData: {
        name: "",
      },
      columnsOppositionTeams: [
      {
          label: "Team name",
          field: "teamName",
        },
        {
          label: "Pitch type",
          field: "pitchType",
        },
        {
          label: "Pitch size",
          field: "pitch_size",
        },
        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },

      ],
      columnsTeams: [
        {
          label: "",
          field: "teamIcon",
        },
        {
          label: "Team Name",
          field: "teamName",
        },
        {
          label: "League Name",
          field: "leagueName",
        },
        {
          label: "Season Start Date",
          field: "season_start_date",
        },
        {
          label: "Season End Date",
          field: "season_end_date",
        },
        {
          label: "Age Group",
          field: "age_group",
        },
        {
          label: "coach_id",
          field: "coach_id",
        },
        {
          label: "Coach name",
          field: "name",
        },
        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      columns: [
        {
          label: "image",
          field: "userImage",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Position",
          field: "position",
        },
        {
          label: "Age group",
          field: "age_group",
        },
        {
          label: "Dob",
          field: "dob",
        },
        {
          label: "Team",
          field: "teamName",
        },
        {
          label: "Type",
          field: "trialistId",
        },
        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      columnsStaff: [
        {
          label: "image",
          field: "userImage",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "user type",
          field: "userType",
        },

        {
          label: "Dob",
          field: "dob",
        },
        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      selectedOppositionTeam: {},
      selectedPlayer: {},
      selectedTeam: {},
      selectedCoachesForSelectedTeam: [],
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      playersOtherTeamsData: [],
      leagueTeams: [],
      editMode: false,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile"]),
  },
  async created() {
    this.loadingStuff = true;
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      this.teamsAndUserCall();

      this.loadingStuff = false;
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );

      this.getUserByIdCall(clientId);
      this.loadingStuff = false;
    }
    this.getAllOppositionTeams();
    },
  methods: {
    openAddOppositionTeamModal(team) {
      if (team === undefined) {
        this.selectedOppositionTeam = {};
        this.editMode = false;
      }
      else {
        this.editMode = true;
        this.selectedOppositionTeam = team;
      }
      
      this.$bvModal.show("addOppositionTeam");
    },
    async deleteOppositionTeam(team) {
      const data = {
        action: "deleteOppositionTeam",
        id: team.id
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllOppositionTeams();
        this.$bvToast.toast(responce.data, {
          title: "Team deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }



    },
    async getAllOppositionTeams() {
      const data = {
        action: "getAllOppositionTeams",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      this.leagueTeams = responce.data;
    },
    async editOppositionTeam() {
      const data = {
        action: "editOppositionTeam",
        ...this.selectedOppositionTeam
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.$bvModal.hide("addOppositionTeam");
        this.getAllOppositionTeams();
        this.$bvToast.toast(responce.data, {
          title: "Team edited",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

    },
    async addOppositionTeam() {
      const data = {
        action: "addOppositionTeam",
        clubId: this.userData.clubId,
        ...this.selectedOppositionTeam
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.$bvModal.hide("addOppositionTeam");
        this.getAllOppositionTeams();
        this.$bvToast.toast(responce.data, {
          title: "Team added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

    },
    releasePlayerModal(playerData) {
      this.playerToBeReleased = playerData;
      this.$bvModal.show("areYouSureModal");
    },
    veiwTeam(team) {
      if (
                this.userData.userType === "Admin" ||
                this.userData.userType === "Coach") {
                this.$router.push({
                    name: "teams",
                    params: { teamData: team },
                });
            }

    },
    async archiveUser(userData) {
      userData.userType = "Archived";
      const playerData = {
        ...userData,
        action: "archiveUser",
      };
      const responce = await Users.find(playerData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.teamsAndUserCall();
        this.playerToBeReleased = null;
        this.$bvToast.toast(responce.data, {
          title: "Player archived",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async removeCoachFromTeam(coachId) {
      const userData = {
        coach_id: coachId,
        teamId: this.selectedTeam.id,
        action: "removeCoachFromTeam",
      };
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllCoachesForTeam(this.selectedTeam.id);

        this.$bvToast.toast(responce.data, {
          title: "Coach removed from team",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

      }
    },
    async removePlayerFromTeam(playerId, teamId) {
      const userData = {
        user_id: playerId,
        teamId: teamId,
        action: "removePlayerFromTeam",
      };
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player removed from team") {
        this.getPlayersOtherTeams(this.selectedPlayer);
        this.$bvToast.toast(responce.data, {
          title: "removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    async addAnotherCoachCall(coachId, teamId, coach_type) {
      const userData = {
        coachId: coachId,
        teamId: teamId,
        coach_type: coach_type,
        action: "addCoachToTeam",
        };
        const responce = await Teams.find(userData).catch((error) => {
          console.log(error);
        });

        this.changeHeadCoach = false;
        this.changeCoach = false;
        this.getAllCoachesForTeam(teamId);

    },
    updateCurrentSelectedTeamCoachName(coachName, coachId, oldCoachId, coach_type) {
      this.selectedTeam.name = coachName;
      this.selectedTeam.oldCoach_id = oldCoachId;
      this.selectedTeam.coach_id = coachId;
      this.selectedTeam.coach_type = coach_type;
    },
    async findCoachByName() {
      if (this.selectedCoach.name !== undefined) {
        const userData = {
          keyword: this.selectedCoach.name,
          action: "findCoachesByName",
        };
        const responce = await Users.find(userData).catch((error) => {
          console.log(error);
        });

        if (responce.data) {
          this.selectedCoachesData = responce.data;
        }
      }
    },
    showCoachMissingValidation(value) {
      this.missingCoachId = value;
    },
    async getAllUsersForCoach() {
      const userData = {
        clubId: this.userData.clubId,
        coach_id: this.userData.id,
        action: "getAllUsersForCoach",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allPlayersData = responce.data;
      }
    },
    teamsAndUserCall() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        if (this.userData.userType === "Admin") {
          this.getUserByClubId();
          this.getAllStaffByClubId();
        }

        if (this.userData.userType === "Coach") {
          this.getAllUsersForCoach();
        }
        this.getAllteams();
      } else {
        this.selectedNav = "players";
        this.getAllteams();
        this.allPlayersData.push(this.userData);
      }
    },
    async addNewTeam() {

      if (this.selectedCoach.hasOwnProperty("id") === true) {
        this.showCoachMissingValidation(false);
        this.loadingStuff = true;
        this.newTeamData.season_start_date = this.fotmatDatesForDb(
          this.newTeamData.season_start_date
        );
        this.newTeamData.season_end_date = this.fotmatDatesForDb(
          this.newTeamData.season_end_date
        );

        const teamData = {
          ...this.newTeamData,
          clubId: this.userData.clubId,
          coach_id: this.selectedCoach.id,
          action: "addTeam",
        };
        const responce = await Teams.find(teamData).catch((error) => {
          console.log(error);
        });

        if (responce.data === "Team added") {
          this.teamsAndUserCall();
          this.$bvModal.hide("addNewTeam");
          this.loadingStuff = false;
          this.$bvToast.toast("Team added", {
            title: "added",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      } else {
        this.showCoachMissingValidation(true);
      }
    },
    async upDateTeam() {
      let coachId = null;
      let oldCoachId = null;

      this.loadingStuff = true;
      this.selectedTeam.season_start_date = this.fotmatDatesForDb(
        this.selectedTeam.season_start_date
      );
      this.selectedTeam.season_end_date = this.fotmatDatesForDb(
        this.selectedTeam.season_end_date
      );

      if (this.selectedCoach.id === undefined) {
        coachId = this.selectedTeam.coach_id;
        oldCoachId = this.selectedTeam.oldCoach_id;
      }

      const teamData = {
        ...this.selectedTeam,
        coach_id: coachId,
        oldCoach_id: oldCoachId,
        action: "editTeam",
      };
      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Team updated") {
        this.teamsAndUserCall();
        this.$bvModal.hide("editTeam");
        this.loadingStuff = false;
        this.changeHeadCoach = false;
        this.changeCoach = false;
        this.$bvToast.toast("Team updated", {
          title: "updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    populateValidationArray(validationData) {
      return validationData.map((validation) => {
        return validation === true;
      });
    },
    filterValidationResults(validationResults) {
      return validationResults.filter((result) => {
        return result === true;
      });
    },
    togglevalaidationErrors(name, validationState) {
      this.validationValues[name].state = validationState;
    },
    requiredValidation(value) {
      return !value || value.length === 0;
    },
    checkChangeVlaidation(value, nameOfElementToValidate) {
      if (nameOfElementToValidate === "email") {
        const validated = this.requiredEmailValidation(value);
        validated === true
          ? (this.validationValues[nameOfElementToValidate].state = false)
          : (this.validationValues[nameOfElementToValidate].state = true);
      } else {
        this[nameOfElementToValidate].length > 0
          ? (this.validationValues[nameOfElementToValidate].state = false)
          : (this.validationValues[nameOfElementToValidate].state = true);
      }
    },
    setValidationMessage() {
      if (this.needsValidation.length === 0) {
        this.validationMessage = null;
        this.needsValidation = [];
      } else {
        this.validationMessage =
          "Please fix the validation errors before you can proceed.";
        this.isLoading = false;
      }
    },
    reCheckValidation() {
      let validation = this.needsValidation.length === 0;
      validation = this.requiredEmailValidation(this.selectedPlayer.email);
      this.setValidationMessage();
      return validation;
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    validationCheck() {
      let validationData = [];
      this.needsValidation = [];
      validationData.push(
        this.setUpValidation(this.selectedPlayer.email, "email")
      );

      let validationResults = this.populateValidationArray(validationData);
      this.needsValidation = this.filterValidationResults(validationResults);
      return this.reCheckValidation();
    },
    setUpValidation(validationValue, validationName) {
      let validationstate = false;
      if (validationName === "email") {
        validationstate = !this.requiredEmailValidation(validationValue);
      } else {
        validationstate = this.requiredValidation(validationValue);
      }
      this.togglevalaidationErrors(validationName, validationstate);
      return validationstate;
    },
    getUserByIdCall(clientId) {
      this.allPlayersData = [];
      this.getUserById(clientId).then((response) => {
        if (response === true) {
          if (
            this.userData.userType === "Admin" ||
            this.userData.userType === "Coach"
          ) {
            if (this.userData.userType === "Admin") {
              this.getUserByClubId();
              this.getAllStaffByClubId();
            }

            if (this.userData.userType === "Coach") {
              this.getAllUsersForCoach();
            }
            this.getAllteams();
          } else {
            this.selectedNav = "players";
            this.getAllteams();
            this.allPlayersData.push(this.userData);
          }
          this.loadingStuff = false;
        }
      });
    },
    async changePassword() {
      if (this.oldPassword === null && this.newPassword === null) {
        return;
      }
      this.loadingStuff = true;
      const userData = {
        email: this.userData.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        id: this.userData.id,
        action: "passwordReset",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadingStuff = false;
        this.$bvToast.toast("Pasword changed successfully.", {
          title: "Pasword changed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      } else {
        this.loadingStuff = false;
        this.$bvToast.toast("Invalid Username or Password!", {
          title: "Pasword error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    async addPlayerToNewTeam(event) {
      const userData = {
        user_id: this.selectedPlayer.id,
        teamId: event.target.value,
        action: "addPlayerToNewTeam",
      };

      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.getPlayersOtherTeams(this.selectedPlayer);
        if (responce.data === "User team created") {
          this.$bvToast.toast("Player added to new team", {
            title: "added",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      }
    },
    async changePlayersTeam(event) {
      this.loadingStuff = true;
      const userData = {
        user_id: this.selectedPlayer.id,
        teamId: event.target.value,
        oldTeamId: this.selectedPlayer.teamId,
        action: "changePlayersTeam",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        if (responce.status === 200) {
          if (this.userData.userType === "Admin") {
            this.getUserByClubId();
            this.getAllStaffByClubId();
          }
          if (this.userData.userType === "Coach") {
            this.getAllUsersForCoach();
            this.getCoachAllDataById();
          }
          if (this.userData.userType === "User") {
            this.getUserByIdCall(this.selectedPlayer.id);

          }
          this.showTeamsSelection = false;
          this.selectedPlayer.teamId = Number(event.target.value);
          this.selectedPlayerTeamValue = null;
          this.loadingStuff = false;
          this.$bvToast.toast("Users team changed", {
            title: "updated",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      }
    },
    getTeamNameFromId(id) {
      if (id === "") {
        id = undefined;
      }
      let arrayResult = null;
      if (this.teams.length > 0 && id !== undefined) {
        arrayResult = this.teams.filter((team) => {
          return team.id == id;
        });
      }
      if (arrayResult !== null && arrayResult.length > 0) {
        return arrayResult !== null ? arrayResult[0].teamName : "";
      }
    },
    editUserModalClose() {
      this.selectedPlayer = {};
      this.loadingStuff = false;
    },
    resetPlayersOtherTeamsData() {
      this.playersOtherTeamsData = [];
      this.showTeamsSelection = false;
      this.showTeamsSelectionAddTeam = false;
    },
    async getPlayersOtherTeams(playerData) {
      this.resetPlayersOtherTeamsData();
      const userData = {
        defaultTeamId: playerData.teamId,
        user_id: playerData.id,
        action: "getPlayersOtherTeams",
      };
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.playersOtherTeamsData = responce.data;
      }
    },
    openEditUserModal(playerData) {
      this.loadingStuff = true;
      this.selectedPlayer = { ...playerData };
      this.getPlayersOtherTeams(playerData);
      this.showModal("editUser");
      this.loadingStuff = false;
    },
    resetSelectedCoachata() {
      this.changeCoach = false;
      this.selectedCoachesData = [];
      this.selectedCoach = {};
    },
    resetNewTeamData() {
      this.newTeamData = {
        teamName: "",
        leagueName: "",
        clubId: "",
        league_link: "",
        season_start_date: null,
        season_end_date: null,
        age_group: "0000",
      };
    },
    openAddNewTeamModal() {
      this.resetNewTeamData();
      this.resetSelectedCoachata();
      this.showModal("addNewTeam");
    },
    openEditTeamModal(teamData) {
      this.resetSelectedCoachata();
      this.getAllCoachesForTeam(teamData.id);
      this.selectedTeam = { ...teamData };
      this.showModal("editTeam");
    },
    async getAllCoachesForTeam(teamId) {
      const teamData = {
        teamId: teamId,
        action: "getAllCoachesForTeam",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.selectedCoachesForSelectedTeam = responce.data;
      }

    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    async getAllStaffByClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllStaffByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allStaffData = responce.data;
      }
    },
    async getCoachAllDataById() {
      const userData = {
        coachId: this.userData.id,
        action: "getCoachAllDataById",
      };

      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allStaffData = responce.data;
      }

    },
    async getAllArchivedUsersByClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllArchivedUsersByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allArchivedPlayersData = responce.data;
      }
    },
    async getUserByClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUserByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allPlayersData = responce.data;
        this.getAllArchivedUsersByClubId();
      }
    },
    async getUserById(clientId) {
      console.log(clientId);
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async upDatePlayerProfile() {
      let selectedPlayerId = this.selectedPlayer.id;
      if (
        this.validationCheck() === true &&
        this.selectedPlayer.joined_date !== null &&
        this.selectedPlayer.dob !== null &&
        this.selectedPlayer.dob !== "Invalid date" &&
        this.selectedPlayer.joined_date !== "Invalid date"
      ) {
        this.$bvModal.hide("editUser");
        this.loadingStuff = true;

        this.selectedPlayer.dob !== "0000-00-00"
          ? (this.selectedPlayer.dob = this.fotmatDatesForDb(
              this.selectedPlayer.dob
            ))
          : (this.selectedPlayer.dob = this.fotmatDatesForDb(moment()));

        this.selectedPlayer.joined_date !== "0000-00-00"
          ? (this.selectedPlayer.joined_date = this.fotmatDatesForDb(
              this.selectedPlayer.joined_date
            ))
          : (this.selectedPlayer.joined_date = this.fotmatDatesForDb(moment()));

        if (this.image.imageFile !== null && this.imageFile !== null) {
          this.selectedPlayer.fileName = this.imageFile.imageName;
          this.selectedPlayer.imageFile = this.image;
        }
        const userData = {
          ...this.selectedPlayer,
          action: "upDatePlayerProfile",
        };
        const responce = await Users.find(userData).catch((error) => {
          console.log(error);
        });

        if (responce.data == 1) {
          this.getUsersByUserType(selectedPlayerId);
          this.loadingStuff = false;
          this.$bvToast.toast("Profile updated.", {
            title: "updated",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      }
    },
    getUsersByUserType(selectedPlayerId) {
      if (this.userData.userType === "User") { this.getUserByIdCall(selectedPlayerId); }
      if (this.userData.userType === "Admin") { this.getUserByClubId();  this.getAllStaffByClubId();}
      if (this.userData.userType === "Coach") { this.getAllUsersForCoach(); this.getAllStaffByClubId();}
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
      }
    },
    formatDates(date) {
      return moment(date).format("DD MMM YYYY");
    },
    fotmatDatesForDb(date) {
      console.log(date);
      return date === null ? null : moment(date).format("YYYY-MM-DD");
    },
    changeSubNav(navItem) {
      this.selectedNav = navItem;
      
      if (navItem === "staff") {
        this.getCoachAllDataById();
      }
    },
    async getTeamsByCoachid() {
      const data = {
        action: "getTeamsByCoachid",
        coach_id: this.userData.id,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.teams = responce.data;
      }
    },
    async getAllteams() {
      if (this.userData.userType === "Coach") {
        this.getTeamsByCoachid();
      } else {
        const userData = {
          clubId: this.userData.clubId,
          action: "populateTeamData",
        };
        const responce = await Teams.find(userData).catch((error) => {
          console.log(error);
        });
        if (responce.data) {
          this.teams = this.removeDuplicates(responce.data, 'id');
        }
      }
    },
    removeDuplicates(dataArray, key) {
      return dataArray.filter((team) => team.coach_type === "Head");
      // [...new Map(dataArray.map(item => [item[key], item])).values()]
    },
  },
};
</script>
<style scoped lang="scss">
label.form__label {
  width: 11rem;
}

input#age_group {
  width: 6rem;
}

.training-plan-display {
  width: 100%;
  display: block;
  border: 1px solid #dddddd;
  padding: 1rem;
  margin: 1rem 0;
  max-height: 10rem;
  overflow-y: scroll;
  background: #f9f9f9;

  .selected-training-plan-box {
    border: 1px solid #06569e;
    padding: 1rem;
  }
}

span.current-coach-change-link {
  margin-right: 0.5rem;
  font-weight: bold;
}

img.user-image-small {
  width: 4rem;
  display: block;
  margin: 0 auto;
  border-radius: 400px;
}

.more-team-information {
  margin-top: 1rem;

  h3 {
    font-size: 1rem;
    text-transform: inherit;
  }

  .extra-teams-list {
    border: 1px solid #dddddd;
    padding: 1rem;
    overflow-y: scroll;
    height: 10rem;
    background: #f1f4f8;

    p {
      border: 1px solid #95dab6;
      margin: 0;
      padding: 0.5rem;
      border-radius: 10px;
      margin-bottom: 0.2rem;
      background: #edfef5;
    }
  }
}

.add-other-team-button {
  margin-top: 0.5rem;
  display: block;
}

.coach-header {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}
</style>
