<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <h2>Trialists</h2>

      <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'trialist-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'trialist-tab')"
              href="javascript:;"
              >Trialist</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'leads-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'leads-tab')"
              href="javascript:;"
              >Player leads</a
            >
          </li>

          <li class="tab-item">
            <a
              :class="
                selectedTab === 'signed-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'signed-tab')"
              href="javascript:;"
              >Signed</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'archived-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'archived-tab')"
              href="javascript:;"
              >Archived</a
            >
          </li>
          </ul>

      <template>
        <div class="tabs-wrapper">

          <div
              class="current-week-tab leads-tab tab-content"
              v-if="selectedTab === 'leads-tab'"
            >

            <div class="add-new-lead-wrapper">
              <button
                @click="openLeadsModal('new')"
                class="green-button add-new-lead"
              >
                Add Player lead
              </button>
            </div>
           


              <div class="table-wrapper">

                <table class="w-a-style homework-table" style="margin-bottom:2rem;">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Player</th>
                                <th>Age group</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="playerLead in allPlayerLeadsData"
                                :key="playerLead.id"
                              >
                                <td>{{ formatDateForHuman(playerLead.date) }}</td>
                                <td>{{ playerLead.name }}</td>
                                <td>{{ playerLead.age_group }}</td>
                                <td>{{ playerLead.state }}</td>
                                <td>
                                  <div class="player-game-responce">
                                    <b-button
                                    v-if="playerLead.trialistId === null"
                                      @click="addEditTrialistModel()"
                                      variant="outline-success"
                                    >
                                    <b-icon
                                        icon="plus"
                                        aria-hidden="true"
                                      ></b-icon>
                                      Add lead as a trialist
                                    </b-button>

                                    <b-button
                                      @click="selectedUseChanged(playerLead, 'playerLead')"
                                      variant="outline-success"
                                      ><b-icon
                                        icon="eye"
                                        aria-hidden="true"
                                      ></b-icon>
                                      View profile</b-button
                                    >
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>


              </div>

            </div>
             <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'trialist-tab'"
            >

            <div style="width:100%;">
                <button
                @click="addEditTrialistModel()"
                v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                class="green-button add-new-programme"
              >
                Add trialist
              </button>
            </div>

            <template>
          <section
            class="player-profile-wrapper"
            v-if="liveTrialistsData.length > 0"
          >
            <div
              v-for="trialist in liveTrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist, 'trialist')"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          </div>


          <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'signed-tab'"
            >

            <template>
          <section
            class="player-profile-wrapper"
            v-if="signedtrialistsData.length > 0"
          >
            <div
              v-for="trialist in signedtrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist, 'trialist')"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          
          
          </div>


          <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'archived-tab'"
            >

            <template>
          <section
            class="player-profile-wrapper"
            v-if="archivedtrialistsData.length > 0"
          >
            <div
              v-for="trialist in archivedtrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist, 'trialist')"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          
          </div>



        </div>
      </template>
    </div>
    <b-modal
      id="viewLead"
      size="lg"
      title="Lead information"
      :hide-footer="Boolean(true)"
    >

      <div class="form lead-form-wrapper">

        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <p>{{selectedLeadData.name}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Club name</label>
          <p>{{selectedLeadData.club_name}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>
          <p>{{selectedLeadData.age_group}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Position</label>
          <p>{{selectedLeadData.playing_position}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Status</label>
          <p>{{selectedLeadData.state}}</p>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout name</label>
          <p>{{selectedLeadData.scout_name}}</p>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>
          <p v-html="selectedLeadData.notes"></p>
        </div>

      </div>

  </b-modal>

    <b-modal
      id="addEditLead"
      size="lg"
      :title="editMode === false ? 'Add new lead' : 'Edit lead details'"
      :hide-footer="Boolean(true)"
    >

      <div class="form lead-form-wrapper">

        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Current club name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.club_name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="dob">DOB</label>

            <datepicker 
            v-model="selectedLeadData.dob"           
            name="datepicker"
            id="dob"
            input-class="input-class">
          </datepicker>

        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>

            <datepicker 
            v-model="selectedLeadData.age_group"
            :format="DatePickerFormat"
            minimum-view="year"              
            name="datepicker"
            id="input-id"
            input-class="input-class">
          </datepicker>

        </div>

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedLeadData.playing_position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="Plays in more than one position">
              Play's in more than one position
            </option>
          </select>
        </div>


        <div class="form__item">
          <label class="form__label" for="position">Status</label>
          <select id="position" v-model="selectedLeadData.state">
            <option disabled value="">Please select a state</option>
            <option value="Still to Watch">Still to Watch</option>
            <option value="Invited for trial">Invited for trial</option>
            <option value="Need to watch again">Need to watch again</option>
            <option value="Not at the standard required">Not at the standard required</option>
          </select>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.scout_name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout contact</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.scout_contact"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>

        <wysiwyg v-model="selectedLeadData.notes" />
        </div>

        <div class="form__item" v-if="editMode === false">
          <button 
          @click="addNewPlayerLead()"
          type="submit" 
          class="green-button">Add player lead</button>
        </div>

        <div class="form__item" v-else>
          <button 
          @click="editPlayerLead()"
          type="submit" 
          class="green-button">Edit player lead</button>
        </div>

      </div>

  </b-modal>
   <b-modal
      id="addEditTrialist"
      size="lg"
      :title="editMode === false ? 'Add new trialist' : 'Edit trialist details'"
      :hide-footer="Boolean(true)"
    >
      <form class="form" @submit.prevent="addEditCoachDetails()">
        <div class="form__item">
          <label class="form__label" for="name">Name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTrialistsData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="phone">phone</label>
          <input
            type="number"
            v-model="selectedTrialistsData.phone"
            id="phone"
          />
        </div>

        <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input
              :class="
                validationValues.email.state === true
                  ? 'danger-outline form__input'
                  : 'form__input'
              "
              @change="checkChangeVlaidation(selectedTrialistsData.email, 'email')"
              type="email"
              v-model="selectedTrialistsData.email"
              id="email"
            />
            <p
              class="invalid-feedback"
              v-if="validationValues.email.state === true"
            >
              {{ validationValues.email.text }}
            </p>
          </div>

        <div class="form__item">
            <label class="form__label" for="password">Password</label>
              <input
                type="password"
                v-model="selectedTrialistsData.password"
                id="password"
              />
          </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="dob">DOB</label>
          <datepicker v-model="selectedTrialistsData.dob" name="dob" id="dob" />
        </div>

        <ImageUploader
          @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
          @populateImageFileDataFromUpload="
            imageUploadObjectDataChanged($event)
          "
          @clearImageDataFromUpload="clearImageDataFromUpload()"
          :imageURL="selectedTrialistsData.userImage"
        />

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedTrialistsData.position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="I played more than one position">
              I played more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select team</label>
            <select v-model="selectedTrialistsData.teamId" id="coachSelect">
              <option disabled value="">Please select a team</option>
              <option v-for="team in clubTeams" :value="team.id" :key="team.id">
                {{ team.teamName }}
              </option>
            </select>
          </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="selectedTrialistsData.coach_id" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>


          <div class="form__item uk-form address">
            <label class="form__label" for="address">Address</label>

            <b-form-textarea
              id="address"
              v-model="selectedTrialistsData.address"
              placeholder=""
              rows="8"
            ></b-form-textarea>
          </div>

          <div class="form__item uk-form post_code">
            <label class="form__label" for="post_code">Post code</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.post_code"
              id="post_code"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="place_of_birth">Place of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.place_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="country_of_birth">Country of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.country_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form nationality">
            <label class="form__label" for="nationality">Nationality</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.nationality"
              id="nationality"
            />
          </div>

          <div class="form__item uk-form previous_club">
            <label class="form__label" for="nationality">Previous club</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.previous_club"
              id="previous_club"
            />
          </div>

          <div class="form__item">
          <label class="form__label" for="parent_name"
            >Parent name</label
          >
          <input
            class="form__input"
            type="text"
            v-model="selectedTrialistsData.parent_name"
            id="parent_name"
          />
        </div>

          <div class="form__item">
            <label class="form__label" for="emergency_contact_one"
              >Emergency Contact</label
            >
            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.emergency_contact_one"
              id="emergency_contact_one"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="emergency_contact_two"
              >Emergency Contact two</label
            >
            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.emergency_contact_two"
              id="emergency_contact_two"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Permission</label>
            <b-form-checkbox
              id="checkbox-1"
              v-model="selectedTrialistsData.permission"
              name="checkbox-1"
              >
              Permission to take photographs 
              </b-form-checkbox>
            </div>

            <div class="form__item">
            <label class="form__label" for="position">Medical information</label>

            <b-form-textarea
              id="textarea"
              v-model="selectedTrialistsData.medical"
              placeholder=""
              rows="8"
            ></b-form-textarea>
            </div>


        <div class="form__item">
          <label class="form__label" for="coachSelect"
            >Select trial length</label
          >
          <select
            v-model="selectedTrialistsData.trial_length"
            id="trialLengthSelect"
          >
            <option disabled value="">Please select a trial length</option>
            <option value="6">6 weeks</option>
            <option value="12">12 weeks</option>
          </select>
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="trialStartDate"
            >Trial start date</label
          >
          <datepicker
            v-model="selectedTrialistsData.trial_start_date"
            name="trialStartDate"
            id="trialStartDate"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="preferredFootSelect"
            >Select preferred foot</label
          >
          <select
            v-model="selectedTrialistsData.preferred_foot"
            id="preferredFootSelect"
          >
            <option disabled value="">Please select a preferred foot</option>
            <option disabled value="Right">Right</option>
            <option disabled value="Left">Left</option>
            <option disabled value="Both">Both</option>
          </select>
        </div>

        <div class="form__item">
          <button type="submit" class="green-button">Add trialist</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="ViewTrialistLeadPlayerProfile"
      size="lg"
      modal-class="modal-fullscreen trialist-player-profile"
      title="Player information"
      :hide-footer="Boolean(true)"
      @hide="resetSelectedCoach()">

      <div class="profile-page">

        <div class="intro-header" style="width:100%;">
          <img class="main-image-top" src="./img/pitch.svg" alt="pitch" />
          <div class="user-photo-info">
            <img id="user_photo" v-if="selectedUserData.userImage !== '' && selectedUserData.userImage !== null
                  "
                  :src="getImgUrl(selectedUserData.userImage)"
                  alt=""
                />
                <img id="user_photo" v-else :src="getImgUrl('default-image.jpg')" alt="" />
                <h1><span> Player Profile</span><br> for {{ selectedUserData.name }} </h1>
          </div>

          <div class="sessons-header">
            <div class="header-buttons">
              <h2><img id="small_shirt" src="./img/player-shirt-blue.svg" />Player profile</h2>
              <span class="week-selected-dates-text">
                <span class="selected-user">Name:<span class="highlighted-text">{{ selectedUserData.name }}</span></span>
                <span class="selected-user">DOB:<span class="highlighted-text">{{ dayMonthYear(selectedUserData.dob) }}</span></span>
                <span class="selected-user">Age group:<span class="highlighted-text">{{ selectedUserData.age_group }}</span></span>
                <span class="selected-user">Nationality:<span class="highlighted-text">{{ selectedUserData.nationality }}</span></span>
              </span>
            </div>

          </div>

          <div class="out-come-buttons" v-if="selectedTrialistsData.id !== undefined">
              <button @click="recordOutcome('1')" class="green-button">Sign</button>
              <button @click="recordOutcome('2')" class="red-button">Reject</button>
              <!--<button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>-->
              <p>When you sign a player, they will be automatically registered with the team they tried out for.</p>
            </div>
        </div>


        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedPlayerInfoTab === 'lead-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'lead-tab')"
              href="javascript:;"
              >Lead information</a
            >
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'trial-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'trial-tab')"
              href="javascript:;"
              >Trial information</a>
          </li>

          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'player-stats-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'player-stats-tab')"
              href="javascript:;"
              >Player stats</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'player-history-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'player-history-tab')"
              href="javascript:;"
              >Player history</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'player-documents-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'player-documents-tab')"
              href="javascript:;"
              >Documents</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'player-videos-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'player-videos-tab')"
              href="javascript:;"
              >videos</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a
              :class="
                selectedPlayerInfoTab === 'player-report-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedPlayerInfoTab', 'player-report-tab')"
              href="javascript:;"
              >Trialists full report</a>
          </li>
        </ul>


        <div class="tabs-wrapper">
        <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'lead-tab'"
        >

          <div class="games-info">

              <div class="form lead-form-wrapper">

              <div class="form__item">
              <label class="form__label" for="name">Club name</label>
              <p>{{selectedLeadData.club_name}}</p>
              </div>

              <div class="form__item">
              <label class="form__label" for="name">Age group</label>
              <p>{{selectedLeadData.age_group}}</p>
              </div>

              <div class="form__item">
              <label class="form__label" for="name">Position</label>
              <p>{{selectedLeadData.playing_position}}</p>
              </div>

              <div class="form__item">
              <label class="form__label" for="name">Status</label>
              <p>{{selectedLeadData.state}}</p>
              </div>


              <div class="form__item">
              <label class="form__label" for="name">Scout name</label>
              <p>{{selectedLeadData.scout_name}}</p>
              </div>


              <div class="form__item">
              <label class="form__label" for="name">Scout notes</label>
              <p v-html="selectedLeadData.notes"></p>
              </div>

              </div>

              <div class="player-game-responce">

                <b-button
              variant="outline-warning"
              @click="openLeadsModal('edit', selectedLeadData)"
              ><b-icon
                icon="pencil"
                aria-hidden="true"
              ></b-icon>
              Edit</b-button
              >
              <b-button
              @click="removePlayerLead(selectedLeadData.id)"
              variant="outline-danger"
              ><b-icon
                icon="trash"
                aria-hidden="true"
              ></b-icon>
              Delete</b-button
              >
              </div>
            </div>

          </div>
      </div>

      <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'trial-tab'">

          <div class="games-info">
            <div class="two-col-pop">
              <div class="user-details-pop">
                <p><span>Phone:</span> {{ selectedTrialistsData.phone }}</p>
                <p><span>Email:</span> {{ selectedTrialistsData.email }}</p>
                <p>
                  <span>DOB:</span>
                  {{ formatDateForHuman(selectedTrialistsData.dob) }}
                </p>
                <p><span>Position:</span> {{ selectedTrialistsData.position }}</p>
                <p>
                  <span>Trial length:</span>
                  {{ trialLength(selectedTrialistsData.trial_length) }}
                </p>
                <p>
                  <span>Trial start date:</span>
                  {{ formatDateForHuman(selectedTrialistsData.trial_start_date) }}
                </p>
                <p>
                  <span>Preferred foot:</span>
                  {{ selectedTrialistsData.preferred_foot }}
                </p>

                <p><span>Team:</span> {{ getTeamName(selectedTrialistsData.teamId) }}</p>

                <p>
                  <span>Coach:</span> 
                  {{ selectedTrialistsData.coach_name }}</p>

                  <button style="margin-bottom:2rem;" @click="editTrialistPerformanceReview()" class="green-button">Edit trialist</button>
              </div>
            </div>

            <div class="trial-performance-wrapper">

              <h4>Performance review</h4>

              <div class="form__item">
                <label class="form__label" for="attitude">Attitude</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="skill">Skill</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.skill"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Physique</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.physique"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Mentality</label>
                <div class="ratingStars">
                  <star-rating
                    v-model="selectedTrialistsData.mentality"
                  ></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Leadership</label>
                <div class="ratingStars">
                  <star-rating
                    v-model="selectedTrialistsData.leadership"
                  ></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Professionalism</label>
                <div class="ratingStars">
                  <star-rating
                    v-model="selectedTrialistsData.professionalism"
                  ></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="comments">Comments</label>
                <wysiwyg v-model="selectedTrialistsData.comments" />
              </div>

            </div>

            <div class="form__item">
              <button @click="saveTrialistPerformanceReview()" class="green-button">Save Performance review</button>
            </div>
       
          </div>

        </div>


        <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'player-stats-tab'">

            <div class="games-info">
              <div>
                <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No player stats</p>
                </div>
            </div>
            </div>
          </div>

          <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'player-history-tab'">

            <div class="games-info">
              <div>
                <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No player history</p>
                </div>
            </div>
            </div>
          </div>

          <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'player-documents-tab'">

            <div class="games-info">

              <DocumentUploader
                @getDocumentUploadsCall="getDocumentUploadsCall"
                documentCategory="player"
                :club_id="userData.clubId"
                :user_id="userData.id"
                :selectedDocumentData="selectedDocumentData.approvedAccessList"
              />

              <div
              class="document"
              v-for="document in uploadedDocuments"
              :key="document.id"
              v-b-tooltip.hover
              :title="removeNumberFromStart(document.name)"
            >

              <a :href="`./${document.url}`" target="_blank">
                <img src="../img/documentlib.svg" />
                <h3>{{ removeNumberFromStart(document.name) }}</h3>
              </a>

            </div>
            <div v-if="uploadedDocuments.length === 0">
                <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No documents</p>
                </div>
            </div>


            </div>
          </div>


          
    

        <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'player-report-tab'">

          <div class="games-info">

            <b-button
            class="main-button create-academy-report-button"
            @click="editAcademyReportModal()"
            size="lg"
            variant="outline-warning"
            style="margin-right:1rem;"
            >
            <b-icon icon="pencil" aria-hidden="true"></b-icon> Edit player
            report</b-button
          >

          <div class="player-report-content">

            <iframe
                v-if="showReport === true"
                id="theAcademyReportPdf_preview"
                src=""
              />

          </div>


          </div>

        
        </div>

          <div
          class="tab-content no-flex"
          v-if="selectedPlayerInfoTab === 'player-videos-tab'">

            <div class="games-info">
              <b-button 
                      variant="outline-success"
                      @click="openPlayerVideosModal()"
                      ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                      Add</b-button
                    >

                    <div class="players-videos">
                    <div v-if="playerCvData.videos.length === 0">
                      <div class="empty-state">
                      <img src="../img/emptyStateIllustration.svg" />
                      <p>No players videos</p>
                      </div>
                    </div>

                    <div class="sessions-info" style="margin-bottom: 0.5rem;">

                      <div 
                      class="video-wrapper" 
                      v-for="(item, index) in playerCvData.videos" 
                      :key="index">
                        <a 
                        v-if="item.category === ''"
                        target="_blank"
                        :href="item.video_url"
                        class="session-card">
                    
                        <img src="./img/video.jpg" alt="" />
                          <p class="small-date">Video</p>
                          <h3>{{ item.video_name}}</h3>
                        </a>

                        <div class="buttons-row" v-if="item.category === ''">
                            <b-button @click="openPlayerVideosModal(index)" size="lg" variant="outline-success"> Update</b-button>

                            <b-button @click="deletePlayerVideoLink(item.id)" size="lg" variant="outline-danger">Delete</b-button>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
          </div>
      
    </div>

  </b-modal>

    <b-modal
      id="ViewTrialist"
      size="lg"
      title="Trialist performance review"
      :hide-footer="Boolean(true)"
    >
        <div class="two-col-pop">
          <div class="user-photo-info">
            <img
              v-if="
                selectedTrialistsData.userImage !== '' &&
                selectedTrialistsData.userImage !== null
              "
              :src="getImgUrl(selectedTrialistsData.userImage)"
              alt=""
            />
            <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
          </div>
          <div class="user-details-pop">
            <p><span>Name:</span> {{ selectedTrialistsData.name }}</p>
            <p><span>Phone:</span> {{ selectedTrialistsData.phone }}</p>
            <p><span>Email:</span> {{ selectedTrialistsData.email }}</p>
            <p>
              <span>DOB:</span>
              {{ formatDateForHuman(selectedTrialistsData.dob) }}
            </p>
            <p><span>Position:</span> {{ selectedTrialistsData.position }}</p>
            <p>
              <span>Trial length:</span>
              {{ trialLength(selectedTrialistsData.trial_length) }}
            </p>
            <p>
              <span>Trial start date:</span>
              {{ formatDateForHuman(selectedTrialistsData.trial_start_date) }}
            </p>
            <p>
              <span>Preferred foot:</span>
              {{ selectedTrialistsData.preferred_foot }}
            </p>

            <p><span>Team:</span> {{ getTeamName(selectedTrialistsData.teamId) }}</p>

            <p>
              <span>Coach:</span> 
              {{ selectedTrialistsData.coach_name }}</p>
          </div>
        </div>

        <h4>Performance review</h4>

        <div class="form__item">
          <label class="form__label" for="attitude">Attitude</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="skill">Skill</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.skill"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Physique</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.physique"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Mentality</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.mentality"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Leadership</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.leadership"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Professionalism</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.professionalism"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="comments">Comments</label>
          <wysiwyg v-model="selectedTrialistsData.comments" />
        </div>

        <div class="out-come-buttons">
          <button @click="recordOutcome('1')" class="green-button">Sign</button>
          <button @click="recordOutcome('2')" class="red-button">Reject</button>
          <!--<button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>-->
        </div>

        <p>When you sign a player, they will be automatically registered with the team they tried out for.</p>

        <div class="form__item">
          <button @click="saveTrialistPerformanceReview()" class="green-button">Save trialist</button>
        </div>
    </b-modal>

    <b-modal
          id="playerVideosModal"
          size="lg"
          :hide-footer="Boolean(true)"
          title="Personal profile"
        >
          <div class="player-cv-content">
            <div class="playing-experence-wrapper">
                <div class="each-target playing-experence-item">

                  <div class="form__item">
                      <label class="form__label" for="contact"
                        >Video title:</label
                      >
                      <input
                        class="form__input"
                        type="text"
                        id="club"
                        v-model="selectedPlayerVideoData.video_name"
                      />
                  </div>

                  <div class="form__item">
                      <label class="form__label" for="contact"
                        >Video URL:</label
                      >
                      <input
                        class="form__input"
                        type="text"
                        id="club"
                        v-model="selectedPlayerVideoData.video_url"
                      />
                  </div>

                  <b-button
                  v-b-tooltip.hover
                  title="Save"
                  variant="success"
                  v-if="selectedPlayerVideoDataEdit === false"
                  @click="savePlayerVideoLink()"
                >Save video</b-button>

              <b-button
                  v-b-tooltip.hover
                  title="Update"
                  variant="success"
                  v-else
                  @click="editPlayerVideoLink()"
                >Update video</b-button>
              </div>
            </div>
          
          </div>

      </b-modal>

      <b-modal id="editAcademyReportModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3>Edit Player report</h3>

            <div class="form__item">
              <label class="form__label" for="coaches_comments"
                >Coaches comments:</label
              >

              <b-form-textarea
                id="coaches_comments"
                v-model="academyReport.coaches_comments"
                placeholder="Enter your comments..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >Coaches average score:</label
              >
              <input
                class="form__input"
                type="number"
                id="average_score"
                v-model="academyReport.average_score"
              /> / 30
            </div>

            <div class="buttons-row">
              <b-button
                size="lg"
                variant="outline-warning"
                @click="updateTheAcademyReport(academyReport.id)"
              >
                Update Player report</b-button
              >
            </div>

          </div>
        </b-modal>

      <img
          src="./img/video-icon.jpg"
          alt=""
          class="visually-hidden"
          id="video-icon"
        />
  
        <img
          src="./img/pitch-potions.jpg"
          alt=""
          class="visually-hidden"
          id="pitch_potions"
        />
        <img
          src="./img/mood-example.jpg"
          alt=""
          class="visually-hidden"
          id="mood-example_image"
        />

  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import { USER_POPULATE } from "@/store/actions/user.js";
import { AUTH_SIGNUP } from "@/store/actions/auth";
import dayjs from 'dayjs';
import { Users } from "@/services/user-service.js";
import { Trialists } from "@/services/trialist-service.js";
import { Documents } from "@/services/documents-service.js";
import { PlayerCv } from "@/services/player-cv-service.js";
import { Games } from "@/services/games-service.js";
import Datepicker from "vuejs-datepicker";
import { Teams } from "@/services/teams-service.js";
import { AcademyReport } from "@/services/academy-report-service.js";
import ImageUploader from "../../components/ImageUploader";
import StarRating from "vue-star-rating";
import moment from "moment";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import DocumentUploader from "../../components/doucmentUploader";
import JsPDF from "jspdf";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";

export default {
  name: "Trialists",
  mixins: [removeDuplicatesMixin,extendSeasonEndDateToFullYearMixin,],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    ImageUploader,
    StarRating,
    DocumentUploader,
  },
  data() {
    return {
      loadingStuff: false,
      selectedUserData: {},
      selectedLeadData: {},
      allPlayerLeadsData: [],
      DatePickerFormat: 'yyyy',
      DatePickerFormatFull: 'dd-mm-yyyy',
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        password: {
          state: false,
          text: "Please add password please!",
        },
        dob: {
          state: false,
          text: "Please add a date of birth please!",
        },
      },
      editMode: false,
      trialistsData: [],
      liveTrialistsData: [],
      signedtrialistsData: [],
      archivedtrialistsData: [],
      coachesUsersData: [],
      selectedTab: "trialist-tab",
      teamData: [],
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      selectedTrialistsData: {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
        userType: "User",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      registerUser: {
        img: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        position: "",
        phone: "",
        emergency_contact_one: "",
        userType: "User",
        action: "register",
        teamId: 0,
        permission: 0,
        clubId: null,
        coach_id: 0,
        team_module: 1,
        preferred_foot: 0,
        joined_date: "",
        medical: "",
      },
      coachData: [],
      selectedPlayerInfoTab: "lead-tab",
      uploadedDocuments: [],
      documentData: {
        name: "",
        text: "",
        created_date: moment().format("YYYY-MM-DD"),
        updated_date: null,
        created_by: null,
        edited_by: null,
        signature: false,
        approvedAccessList: {
          teams: [],
          users: [],
        },
      },
      selectedDocumentData: {
        approvedAccessList: {
          teams: [],
          users: [],
          category: "player",
        },
      },
      selectedPlayerVideoData: {},
      selectedPlayerVideoDataEdit: false,
      playerCvData: {
        videos: [],
      },
      showReport: false,
      academyReport: {
        id: null,
        player_id: null,
        seasion: null,
        average_score: 0,
        coaches_comments: "",
        seasion: "",
      },
      season_start_date: "",
      season_end_date: "",
      gamePerformanceData: {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      },
      playerPhysicalPerformanceData: {},
      trainingPerformanceData: {},
      seasonsgamePerformanceReportsForUser: [],
      seasonsTraningPerformanceReportsForUser: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      if (response === true) {
        this.getTeamsByUserId();
        this.getAllTrialists();
        this.loadPlayerLeads();
      }
    });
  },
  methods: {
    async populateAcademyReport() {
      const data = {
        'action': "populateAcademyReport",
        userId: this.selectedUserData.id,
      }
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200 && responce.data.id !== undefined) {
        this.academyReport = responce.data;
      }
    },
    editAcademyReportModal() {
      this.$bvModal.show("editAcademyReportModal"); 
    },
    async updateTheAcademyReport(id) {
      id !== null
      ? this.editAcademyReport()
      : this.saveAcademyReport();
    },
    async editAcademyReport() {
      const data = {
        'action': "editAcademyReport",
        academyReport: this.academyReport,
      }
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
      this.$bvModal.hide("editAcademyReportModal");
    },
    async saveAcademyReport() {
      const data = {
        'action': "saveAcademyReport",
        academyReport: this.academyReport,
      }
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
      this.$bvModal.hide("editAcademyReportModal");
    },
    async getAllgoalsScoredByPlayerId() {
      const data = {
        action: "getAllgoalsScoredByPlayerId",
        userId: this.selectedUserData.id,
        season_start_date: this.season_start_date,
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.goals = responce.data;
      }
    },
    async getAllgoalAssistsByPlayerId() {
      const data = {
        action: "getAllgoalAssistsByPlayerId",
        userId: this.selectedUserData.id,
        season_start_date: this.season_start_date,
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.assists = responce.data;
      }
    },
    dayMonthYearDate(date) {
      return dayjs(date, "YYYY-MM-DD").format("MMM YY");

    },
    produceTheAcademyReport() {
      this.showReport = true;
      this.academyReport.player_id = this.selectedUserData.id;
      this.academyReport.seasion = "2023";
      //this.loadPlayerPhysicalPerformanceData();

      setTimeout(() => {
        let pdf = this.buildTheAcademyReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    buildTheAcademyReportPDF() {
      let previewContainer = document.getElementById("theAcademyReportPdf_preview");
      let shirtImage =  document.getElementById("small_shirt");
      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setFillColor(241, 246, 249);
      pdf.rect(0, 0, 300, 120, "F");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(37).setFont(undefined, "bold");
      pdf.text(45, 18, this.selectedUserData.name, { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(5, 55, `Season: ${this.dayMonthYearDate(this.season_start_date)} - ${this.dayMonthYearDate(this.season_end_date)}`);

      pdf.setFontSize(14).setFont(undefined, "normal");
      pdf.text(5, 65, "Appearances:");
      pdf.text(75, 65, `${this.gamePerformanceData.gamesPlayed}`);

      pdf.text(5, 75, "Goals:");
      pdf.text(75, 75, `${this.gamePerformanceData.goals}`);

      pdf.text(5, 85, "Assists:");
      pdf.text(75, 85, `${this.gamePerformanceData.assists}`);

      pdf.text(5, 95, "Player performace rating:");
      let playerSelfRating;
      this.gamePerformanceData.player_self_rating === null ? playerSelfRating = 0 : playerSelfRating = this.gamePerformanceData.player_self_rating;
      pdf.text(75, 95, `${playerSelfRating}`);

      pdf.text(5, 105, "Game time:");
      let timeOnPitch;
      this.gamePerformanceData.timeOnPitch === null ? timeOnPitch = 0 : timeOnPitch = this.gamePerformanceData.timeOnPitch;
      pdf.text(75, 105, `${timeOnPitch} mins`);

      pdf.text(125, 15, `Dob: ${this.formatDateForHuman(this.selectedUserData.dob)}`).setFont(undefined, "normal");
      pdf
        .text(125, 25, `Position: ${this.selectedUserData.position}`)
        .setFont(undefined, "normal");
      pdf.text(125, 35, `Height: ${this.playerPhysicalPerformanceData.height} cm`).setFont(undefined, "normal");
      pdf.text(125, 45, `Weight:  ${this.playerPhysicalPerformanceData.weight} kg`).setFont(undefined, "normal");
      pdf.text(125, 55, `Age: ${this.selectedUserData.age_group}`).setFont(undefined, "normal");
      pdf.text(125, 65, `Signed: ${this.formatDateForHuman(this.selectedUserData.joined_date)}`).setFont(undefined, "normal");

      let pitchBackground =  document.getElementById("pitch_potions");
      pdf.addImage(pitchBackground, "PNG", 120, 90, 80, 120);

      let height = this.addPlayingPostions(pdf, 85);

      pdf.setFontSize(14).setFont(undefined, "bold");
      pdf.text(125, height, "PLAYING POSITIONS");

      pdf.text(125, height + 135, "PHYSICAL PERFORMANCE");

      pdf.setFontSize(12).setFont(undefined, "normal");
      pdf.text(125, height + 143, "10m SPRINT");
      const tenMsprintTime = this.playerPhysicalPerformanceData.tenM_sprint_time;
      if(tenMsprintTime !== undefined) {
        pdf.text(183, height + 143, tenMsprintTime);
      }

      pdf.text(125, height + 153, "40m SPRINT");
      const fortyMSprintTime = this.playerPhysicalPerformanceData.fortyM_sprint_time;
      if(fortyMSprintTime !== undefined) {
        pdf.text(183, height + 153, `${fortyMSprintTime}s`);
      }

      pdf.text(125, height + 163, "Countermovement jump");
      const cuntermovementJump = this.playerPhysicalPerformanceData.cuntermovement_jump;
      if(cuntermovementJump !== undefined) {
        pdf.text(183, height + 163, `${cuntermovementJump}cm`);
      }

      pdf.text(125, height + 173, "Reactive strength index");
      const reactiveStrengthindex = this.playerPhysicalPerformanceData.reactive_strength_index;
      if(reactiveStrengthindex !== undefined) {
        pdf.text(183, height + 173, `${reactiveStrengthindex}`);
      }

      pdf.text(125, height + 183, "30-15 Intermittent Fitness Test");
      const thirty15IntermittentFitnessTest = this.playerPhysicalPerformanceData.reactive_strength_index;
      if(thirty15IntermittentFitnessTest !== undefined) {
        pdf.text(183, height + 183, `${thirty15IntermittentFitnessTest}`);
      }

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 130, "COACHES COMMENTS");

      pdf.setFontSize(12).setFont(undefined, "normal");

      //let playerPerformanceReview = this.getTheLastPlayerPerformanceReview();
      let paraOneLines = "Please add comments";

      var paraOne = this.academyReport.coaches_comments;
      paraOneLines = pdf.splitTextToSize(paraOne, 115);

      pdf.text(5, 139, paraOneLines);
      let dynamicHeight = pdf.getTextDimensions(paraOneLines);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 150, "Playing videos");
      dynamicHeight.h = this.addPlayerVideos(pdf, dynamicHeight.h + 160);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    addPlayerVideos(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.videos.map( (item, index) => {

        let videoIcon =  document.getElementById("video-icon");
        pdf.addImage(videoIcon, "PNG", 5, height - 7, 10, 10);

        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        height = height + 10;
        let dynamicHeight = {
          h: 5,
        }
        if (index !== 0) {height = this.newPageCheck (height, dynamicHeight, pdf);} 

      });
      return height;
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      this.seasonsgamePerformanceReportsForUser = [];

      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getThisSeasonsgamePerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.seasonsgamePerformanceReportsForUser = responce.data;
        //this.populatePlayerSelfRating();
        this.populateGameStatsForThisSeason("goals");
        this.populateGameStatsForThisSeason("assists");
        this.populateGameStatsForThisSeason("clean_sheet");
        this.populateGameStatsForThisSeason("timeOnPitch");
        this.pupulatePlayerCards();
      }

      //this.populatePostionsPlayed();
    },
    pupulatePlayerCards() {
      this.seasonsgamePerformanceReportsForUser.forEach((player) => {
        if (player.card === "Yellow") {
          this.gamePerformanceData.yellowCardTotal =
            this.gamePerformanceData.yellowCardTotal + 1;
        }
        if (player.card === "Red" || player.card === "two Yellows (red)") {
          this.gamePerformanceData.redCardTotal =
            this.gamePerformanceData.redCardTotal + 1;
        }
      });
    },
    populateGameStatsForThisSeason(typeOfData) {
      this.gamePerformanceData[typeOfData] =
        this.seasonsgamePerformanceReportsForUser.reduce(function (
          accumulator,
          player
        ) {
          return accumulator + Number(player[typeOfData]);
        },
        0);
    },
    addPlayingPostions(pdf, height) {

    pdf.setFontSize(10).setFont(undefined, "normal");

    this.gamePerformanceData.postion_played.map( (item, index) => {
      if (item === "Goalkeeper") {
        pdf.text(158, 201, "GK");
      }

      if (item === "Right Full-back (Wingback)") {
        pdf.text(188.5, 189, "RB");
      }

      if(item === "Center-back left") {
        pdf.text(147, 189, "CB");
      }

      if(item === "Center-back right") {
        pdf.text(167, 189, "CB");
      }

      if(item === "Defensive Midfielder") {
        pdf.text(147, 152, "CM");
      }

      if(item === "Attacking Midfielder") {
        pdf.text(167, 152, "CM");
      }

      if(item === "Right Midfielder (Winger)") {
        pdf.text(188.5, 152, "RW");
      }

      if(item === "Left Midfielder (Winger)") {
        pdf.text(127.5, 152, "LW");
      }


      if(item === "Center Forward (Striker) left") {
        pdf.text(147, 119, "CF");
      }

      if(item === "Center Forward (Striker) right") {
        pdf.text(169, 119, "CF");
      }


      if (item === "Left Full-back (Wingback)") {
        pdf.text(127.5, 189, "LB");
      }

    });

    return height;

    },
    newPageCheck (height, dynamicHeight, pdf) {
      
      if (height >= '210') {
        pdf.addPage()
        return 10
      } else {
        height = Math.round(dynamicHeight.h + height + 10);
        return height
      }
    },
    editTrialistPerformanceReview() {
      this.$bvModal.show("addEditTrialist");
    },
    async savePlayerVideoLink() {
      this.selectedPlayerVideoData.player_id = this.selectedUserData.id;

      const data = {
        playerVideo: this.selectedPlayerVideoData,
        "action": "savePlayerVideoLink",
      }
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.$bvToast.toast("Player video saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerVideosModal"); 

      }
    },
    async deletePlayerVideoLink(id) {
      const data = {
        'action': "deletePlayerVideoLink",
        id: id,
      }
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.$bvToast.toast("Player video  deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

    },
    async populatePlayerVideoLinkData() {
      const data = {
        'action': "populatePlayerVideoLinkData",
        userId: this.selectedUserData.id,
      }
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.videos = responce.data;
      }
    },
    openPlayerVideosModal(index) {
      if ( index !== undefined ) {
        this.selectedPlayerVideoData = this.playerCvData.videos[index];
        this.selectedPlayerVideoDataEdit = true;
      }
      this.$bvModal.show("playerVideosModal");
    },
    removeNumberFromStart(documentName) {
     return documentName.substring(documentName.indexOf('_')+1);
    },
    async getDocumentUploadsCall() {
      this.getDocumentUploadsByUserId();
      this.$bvToast.toast("Player document uploaded.", {
            title: "uploaded",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
    },
    async getDocumentUploadsByUserId() {
      const userData = {
        action: "getDocumentUploadsByUserId",
        user_id: this.selectedTrialistsData.id,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if(response.status === 200) {
        this.uploadedDocuments = response.data;
      }

    },
    viewLeadModal(playerLead) {
      this.selectedLeadData = playerLead;
      this.$bvModal.show("viewLead");
    },
    openLeadsModal(type, playerLead) {
      if (type !== "edit") {
        this.selectedLeadData = {};
        this.selectedLeadData.state = "Still to Watch";
        this.editMode = false;
      } else {
        this.selectedLeadData = playerLead;
        this.editMode = true;
      }

      this.$bvModal.show("addEditLead");
    },
    async loadPlayerLeads() {
      const userData = {
        action: "loadPlayerLeads",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allPlayerLeadsData = responce.data;
      }

    },
    async removePlayerLead(id) {
      const userData = {
        id: id,
        action: "removePlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvToast.toast("Player lead removed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
    },
    async editPlayerLead() {
      if (typeof this.selectedLeadData.age_group === 'object') {
        this.selectedLeadData.age_group = dayjs().format("YYYY");
      }
      const userData = {
        ...this.selectedLeadData,
        action: "editPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvToast.toast("Player lead edited.", {
            title: "edited",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }

    },
    async addNewPlayerLead() {
      this.selectedLeadData.date = dayjs(this.selectedLeadData.date).format("YYYY-MM-DD");
      this.selectedLeadData.age_group = dayjs(this.selectedLeadData.age_group).format("YYYY");
      this.selectedLeadData.dob = dayjs(this.selectedLeadData.dob).format("YYYY-MM-DD");
      const userData = {
        ...this.selectedLeadData,
        action: "addNewPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvToast.toast("New lead added.", {
            title: "added",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
    },
    async checkIfEmailExists(value) {
      const userData = {
        email: value,
        action: "checkEmailexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.validationValues.email.state = true;
        this.validationValues.email.text =
          "Email address aready exits, please change it";
      } else {
        this.validationValues.email.state = false;
        this.validationValues.email.text = "Please add an email address";
      }
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    checkChangeVlaidation(value, elementName) {
      if (elementName === "email") {
        if (value !== "") {
          this.checkIfEmailExists(value);
        } else {
          this.validationValues.email.state = false;
          this.validationValues.email.text = "Please add an email address";
        }
        const validated = this.requiredEmailValidation(value);
        this.validationValues[elementName].state = validated !== true;
      } else {
        JSON.stringify(value).length > 0
          ? (this.validationValues[elementName].state = false)
          : (this.validationValues[elementName].state = true);
      }
    },
    getTimeLeftInTrial(trialStartDate) {
      console.log(trialStartDate);
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async getAllGamesAttendedById() {
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getAllGamesAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),

        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.gamePerformanceData.gamesPlayed = responce.data;
    },
    SetTrainingHoursThisSeason() {
      return this.trainingPerformanceData.traningAttended !== 0
        ? (this.trainingPerformanceData.time =
            this.trainingPerformanceData.traningAttended * 60)
        : (this.trainingPerformanceData.time = 0);
    },
    async getAllTraningAttendedById() {
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getAllTraningAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.trainingPerformanceData.traningAttended = responce.data;
      this.SetTrainingHoursThisSeason();
    },
    resetGamePerformanceData() {
      this.gamePerformanceData = {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      };
    },
    resetplayerTrainingData() {
      this.trainingPerformanceData = {};
    },
    resetAllPlayerData() {
      this.resetGamePerformanceData();
      this.resetplayerTrainingData();
    },
    loadPlayersProfilePerformanceData () {
      this.resetAllPlayerData();
      this.getAllGamesAttendedById();
      this.getAllTraningAttendedById();
      this.getThisSeasonsgamePerformanceReportsForUser();
    },
    async signPlayer() {
      const data = {
        trialistId: this.selectedTrialistsData.id,
        action: "signTrialistPlayer",
      }

      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.saveTrialistPerformanceReview();
        this.getAllTrialists();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist signed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });

          this.loadPlayersProfilePerformanceData();
      }
    },
    getCoachesName(coach_id) {
      let name = "";
      this.coachData.map((coach) => {
        if (coach.id === coach_id) {
          name = coach.name;
        }
      });
      return name;
    },
    outComeActions(value) {
      value == 1 ? this.signPlayer() : this.saveTrialistPerformanceReview();
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.userData.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async removeTrialistFromDB(id) {
      const userData = {
        action: "removeTrialist",
        id: id,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist removed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
      }

    },
    recordOutcome(value) {
      this.selectedTrialistsData.outcome = value;
      this.outComeActions(value);
    },
    outComeText(outCome) {
      switch (outCome) {
        case 0:
          return "Under consideration";
        case 1:
          return "Signed";
        case 2:
          return "Rejected";
        case 3:
          return "Waiting list";
      }
    },
    totalScore(trialist) {
      let values = [
        trialist.attitude,
        trialist.skill,
        trialist.physique,
        trialist.mentality,
        trialist.leadership,
        trialist.professionalism,
      ];

      var totalYears = values.reduce(function (accumulator, value) {
        return accumulator + +value;
      }, 0);

      return totalYears;
    },
    getTeamName(teamId) {
      if (teamId === "") {
        return teamId;
      }
      var selectedTeam = this.clubTeams.find(function (team) {
        return team.id == teamId;
      });

      return selectedTeam !== undefined ? selectedTeam.teamName : "";
    },
    trialLength(number) {
      if (number == "6") {
        return "6 weeks";
      }
      if (number == "12") {
        return "12 weeks";
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;

      if (selectedTabValue === 'player-documents-tab') {
        if (this.selectedTrialistsData.id !== undefined) {
          this.selectedDocumentData.approvedAccessList.users = [];
          this.selectedDocumentData.approvedAccessList.users.push({id: this.selectedTrialistsData.id, name: this.selectedTrialistsData.name});
        }
        
        this.getDocumentUploadsByUserId();
      }

      if (selectedTabValue === 'player-videos-tab') {
        this.populatePlayerVideoLinkData();
      }

      if (selectedTabValue === 'player-report-tab') {
        this.getAllgoalsScoredByPlayerId();
        this.getAllgoalAssistsByPlayerId();
        this.populatePlayerVideoLinkData();
        this.populateAcademyReport();
        this.produceTheAcademyReport();
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    trialistDataconvertStringsToNumbers(trialist) {
      trialist.attitude = parseInt(trialist.attitude);
      trialist.skill = parseInt(trialist.skill);
      trialist.physique = parseInt(trialist.physique);
      trialist.mentality = parseInt(trialist.mentality);
      trialist.leadership = parseInt(trialist.leadership);
      trialist.professionalism = parseInt(trialist.professionalism);

      return trialist;
    },
    populateLeadAndTrialistData(playerData) {
      //get trialist id
      let trialistData = this.trialistDataconvertStringsToNumbers(playerData);
        this.selectedTrialistsData = trialistData;

        this.getUserByTrialistId(this.selectedTrialistsData.id);
        //populate player lead data
        this.selectedLeadData = this.allPlayerLeadsData.find(lead => lead.trialistId === playerData.id);
    },
    selectedUseChanged(playerData, type) {
      if (type === "trialist") {
        this.populateLeadAndTrialistData(playerData);
      } else {
        //lead mode
        if (playerData.trialistId !== null) {
          this.selectedTrialistsData = this.trialistsData.find(lead => lead.id === playerData.trialistId);
          this.getUserByTrialistId(this.selectedTrialistsData.id);
          this.selectedLeadData = playerData;
        } else {
          this.selectedLeadData = playerData;
          this.selectedUserData = {
            name: playerData.name,
            age_group: playerData.age_group,
            userImage: null,
          }
        }
      }
      this.showModal("ViewTrialistLeadPlayerProfile");
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserByTrialistId(trialistsId) {
      const userData = {
        trialistsId: trialistsId,
        action: "getUserByTrialistId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.selectedUserData = responce.data;
        this.loadPlayersProfilePerformanceData();
      }

    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    addEditCoachDetails() {
      this.edit !== true
        ? this.addTrialistDetailsSubmit()
        : this.editTrialistDetailsSubmit();
    },
    async saveTrialistPerformanceReview() {
      this.loadingStuff = true;
      const userData = {
        action: "updateTrialist",
        ...this.selectedTrialistsData,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      this.getAllTrialists();
      this.hideModal("ViewTrialist");
      this.loadingStuff = false;
      this.$bvToast.toast("Trialist updated.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
    },
    filterTrialistsIntoCategories() {
      this.liveTrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 0);
      this.signedtrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 1);
      this.archivedtrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 2);
    },
    async getAllTrialists() {
      const userData = {
        action: "getTrialists",
        clubId: this.userData.clubId,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trialistsData = responce.data;
      }

      this.filterTrialistsIntoCategories();

      this.coachData = await this.populateCoachesData();
      this.teamData = await this.populateTeamData();

    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = this.removeDuplicates(responce.data, "id");
      this.season_start_date = this.clubTeams[0].season_start_date;
      this.season_end_date = this.clubTeams[0].season_end_date;

      localStorage.setItem(
          "currentPlayingseasonStartDate",
          this.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          this.season_end_date
        );
    },
    async addTrialistDetailsSubmit() {
      this.loadingStuff = true;
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedTrialistsData.fileName = this.imageFile.imageName;
        this.selectedTrialistsData.imageFile = this.image;
      }
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.dob
      );
      this.selectedTrialistsData.age_group = this.formatAgeGroupForSQL(this.selectedTrialistsData.dob);

      this.selectedTrialistsData.coach_name = this.getCoachesName(this.selectedTrialistsData.coach_id);
      this.selectedTrialistsData.clubId = this.userData.clubId;
      this.selectedTrialistsData.permission = true ? 1 : 0;
      this.selectedTrialistsData.userType = "User";
      const userData = {
        ...this.selectedTrialistsData,
        action: "addTrialist",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.$bvModal.hide("addEditTrialist");
        this.loadingStuff = false;
        this.hideModal("ViewTrialist");
        this.$bvToast.toast("Trialist added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addEditTrialistModel(trialist) {
      if (trialist !== undefined) {
        this.selectedTrialistsData = { ...trialist };
      }
      this.showModal("addEditTrialist");
    },
    resetSelectedCoach() {
      this.selectedTrialistsData = {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      };

      this.selectedLeadData = {}
      this.selectedTab = "leads-tab";
      this.selectedPlayerInfoTab = "lead-tab";

    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    fotmatDatesForDb(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
    dayMonthYear(date) {
      return moment(date).format("D MMM YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>


.player-report-content {

margin-top: 1rem;
width: 100%;
height: 50rem;

iframe {
  width: 100%;
  height:100%;
}


}

.intro-header {
  border-bottom: none;
}



.player-cv-content {
    margin-top: 0rem;
    width: 100%;
    height: auto;
}

#small_shirt {
    width: 2.5rem;
    margin-right: 1.1rem;
}

.lead-form-wrapper {
  
  .form__item {
        display: flex !important;
        flex-wrap: wrap;
        gap: 5rem;

        .form__label {
          width: 7rem;
        }

        .form__input {
          width: 71%;
        }

        p {
          margin:0;
        }
    }

}

.player-cv-content {
      .form__input {
        width: 100%!important;
      }
    }

.leads-tab {
  padding:1rem;

  .add-new-lead-wrapper {
  width:100%;
  margin-bottom:1rem;
} 

}

.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.trial-performance-wrapper {
  margin-bottom:2rem;
}

.out-come-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  height: 7rem;
  margin-top: 4rem;

p {
  width:100%;
}

  button {
    min-width: 9rem;
  }

  .red-button {
    background: #d85c64;
  }

  .yellow-button {
    background: #ffc107;
  }
}

.outCome-Under {
  border: 1px solid #004ca3;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #008afc;
  background: #e8f5ff;
}

.outCome-Signed {
  border: 1px solid #18a300;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #18a300;
  background: #ecffe8;
}

.outCome-Rejected {
  border: 1px solid #d85c64;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #d85c64;
  background: #ffe8ec;
}

.outCome-Waiting {
  border: 1px solid #ffc107;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #ffc107;
  background: #fffbe8;
}

.trialist-tab {
  padding:2rem;
}

.sessions-info .session-card {
  display:block;
}

.document {
  max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    img {
      width: 40%;
    }

   h3 {
     margin-top: 1rem;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-transform: inherit;
   }
}

.lead-form-wrapper {
  margin-bottom:1rem;
}


@media screen and (min-width: 1200px) {

    .video-wrapper {
      width: 20%;
    }

    .document {
      width:20%;
}


}


@media screen and (min-width: 1600px) {
    .games-info {
        margin: 2rem 0;
    }

}
</style>
